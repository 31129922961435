import React, { useState } from "react";
import { FaStar } from "react-icons/fa6";
import { CiCircleCheck } from "react-icons/ci";
import "./comment.scss";

function CommentApp() {
  const [comment, setComment] = useState(""); //Lưu nội dung bình luận mà người dùng nhập vào ô textarea.
  const [ratingState, setRatingState] = useState(0); // Số sao hiện tại được chọn
  const [commentsList, setCommentsList] = useState([]); //Một mảng chứa danh sách tất cả các bình luận. Mỗi bình luận là một đối tượng

  // Hàm xử lý khi thay đổi nội dung ô input
  const handleInputChange = (e) => {
    setComment(e.target.value);
  };

  // Hàm xử lý khi thay đổi số sao
  const handleRatingChange = (newRating) => {
    setRatingState(newRating);
  };

  // Hàm xử lý khi nhấn nút gửi
  const handleAddComment = () => {
    if (comment.trim() !== "" && ratingState > 0) {
      //nội dung không được rỗng và số sao phải lớn hơn 0
      const now = new Date(); // Lấy giờ hiện tại
      const formattedTime = now.toLocaleTimeString(); // Định dạng giờ hiện tại (HH:mm:ss)

      // Thêm bình luận mới với nội dung, số sao và giờ đánh giá
      const newComment = {
        // 3 đối tượng của commentsList
        content: comment,
        rating: ratingState,
        time: formattedTime,
      };
      setCommentsList([newComment, ...commentsList]); // Thêm bình luận mới vào đầu danh sách

      setComment(""); // Reset ô input
      setRatingState(0); // Reset số sao
    }
  };

  return (
    <div>
      <h3 style={{ marginTop: "10px" }}>Thêm bình luận</h3>
      <div className="comment">
        <textarea
          value={comment}
          onChange={handleInputChange}
          placeholder="Viết đánh giá..."
          rows="1"
        ></textarea>
        <div style={{ margin: "10px 0" }}>
          {[...Array(5)].map((_, i) => {
            const starValue = i + 1;
            return (
              <FaStar
                key={i}
                onClick={() => handleRatingChange(starValue)}
                style={{
                  cursor: "pointer",
                  color: starValue <= ratingState ? "#ffc107" : "#e4e5e9", //sao nào nhỏ hơn hoặc bằng sao bạn click thì màu vằng lớn hơn thì màu trắng
                }}
              />
            );
          })}
        </div>
        <button className="comment-btn" onClick={handleAddComment}>
          Gửi
        </button>
      </div>
      <div style={{ marginTop: "20px" }}>
        <h4>Tất cả bình luận:</h4>
        {commentsList.length > 0 ? (
          <ul style={{ listStyle: "none", padding: 0 }}>
            {commentsList.map((item, index) => (
              <li key={index}>
                <div className="box-comment">
                  <div className="box-image">
                    <img
                      src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                      alt=""
                    />
                  </div>
                  <div className="info">
                    <div className="info-top">
                      <h3>Bạn</h3>
                      <div className="icon">
                        <CiCircleCheck />
                      </div>
                      <span>Đã đánh giá lúc {item.time}</span>
                    </div>
                    <div className="stars">
                      {[...Array(item.rating)].map((_, i) => (
                        <FaStar key={i} />
                      ))}
                    </div>
                    <span>{item.content}</span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default CommentApp;
