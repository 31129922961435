import React from "react";
import { useParams } from "react-router-dom";
import { Congnghe, Hoidap } from "../../Data/Bantin";
import img4 from "../../../assets/image/ban_tin/img4.jpg";
import "./cn_detail.scss";
import Binhluan from "../Binhluan/Binhluan";

const CN_Detail = () => {
  const { id } = useParams();
  //useParams là một hook được cung cấp bởi thư viện react-router-dom. Nó được dùng để truy cập các tham số (parameters) trên URL trong một component.
  //Khi người dùng truy cập URL /news/1, useParams sẽ trả về một đối tượng chứa các tham số từ URL.
  //const { id } = useParams(); trích xuất giá trị id từ đối tượng này, do đó biến id sẽ chứa chuỗi "1".

  const hoidap = Hoidap.find((item) => item.id === parseInt(id));

  return (
    <>
      <div className="max_container">
        <div className="news-detail">
          <div className="content">
            <div className="title">
              <h2>
                {/* {news && news.title} */}
                {hoidap.description}
              </h2>
            </div>
            <div className="news-img">
              <img src={hoidap.image} alt="" />
            </div>
            <div className="news-des">
              <h3>1. Thương hiệu uy tín, chất lượng</h3>
              <p>
                Electrolux nổi tiếng là thương hiệu sản xuất đồ gia dụng lớn thứ
                hai trên thế giới, được thành lập vào năm 1919 và có trụ sở
                chính đặt tại Thụy Điển. Electrolux là một nhãn hiệu cao cấp, có
                nhiều sản phẩm sáng tạo với thiết kế độc đáo, hàng năm có khoảng
                40 triệu sản phẩm được tiêu thụ tại trên 150 quốc gia.ưu <br />
                Với đội ngũ chuyên gia có kỹ thuật cao Electrolux không ngừng
                nghiên cứu, cải tiến sản phẩm nhằm đáp ứng nhu cầu của người
                tiêu dùng. Vì thế, máy giặt Electrolux không chỉ nổi bật bởi
                kiểu dáng sang trọng mà còn về chất lượng với nhiều công nghệ
                hiện đại được trang bị giúp nâng cao chất lượng giặt sạch vượt
                trội, vận hành tốt và tiết kiệm được nhiều chi phí khác.
              </p>
            </div>
            <div className="news-des">
              <h3>2. Thiết kế sang trọng, đẹp mắt và có tính khả dụng cao</h3>
              <p>
                Máy giặt Electrolux sở hữu nhiều thiết kế sang trọng, độc đáo và
                mới lạ thu hút bởi vẻ ngoài đẹp mắt, hiện máy giặt Electrolux có
                2 loại là máy giặt cửa trên và máy giặt cửa trước. Đối với máy
                giặt Electrolux cửa trên thì kiểu dáng đẹp, khối lượng giặt từ
                7.0 kg đến 11.0 kg, dòng EWT sử dụng công nghệ T-Drive giặt
                sạch, chống xoắn quần áo, không bị dính bột giặt sau khi giặt,
                tiết kiệm nước và thân thiện với người dùng. Máy giặt Electrolux
                cửa trước với vẻ ngoài sang trọng màu sắc thanh lịch và đa dạng,
                khối lượng giặt có nhiều loại để lựa chọn từ 7.0kg đến 11.0kg,
                ứng dụng nhiều công nghệ giặt tiên tiến. Đặc biệt, máy giặt
                Electrolux cửa trước đang dẫn đầu trên thị trường Việt Nam hiện
                nay.
              </p>
            </div>
            <div className="news-img">
              <img src={hoidap.image} alt="" />
            </div>
          </div>
          <section>
            <Binhluan />
          </section>
        </div>
      </div>
    </>
  );
};

export default CN_Detail;
