import React, { useState } from "react";
import Homeproduct from "../Data/HomeProduct_data";
import "./detail.scss";
import { useParams } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { formater } from "../utils/format";
import Rating from "../Rating/Rating";

const Detail = ({ addToCart }) => {
  const { id } = useParams(); // Lấy ID từ URL
  const product = Homeproduct.find((item) => item.id === parseInt(id)); // Tìm sản phẩm theo ID
  // State để lưu ảnh chính
  const [mainImage, setMainImage] = useState(product?.image[0]); // ? : Nó được sử dụng để tránh lỗi khi truy cập vào một thuộc tính của một đối tượng có thể là undefined hoặc null
  const [selectedImage, setSelectedImage] = useState(product?.image[0]);
  if (!product) {
    return <div>Sản phẩm không tồn tại</div>;
  }
  // console.log("Product Image URL: ", product.image);

  return (
    <>
      <div className="max_container">
        <div className="product-detail">
          <div className="container">
            <div className="box-detail">
              <div className="box-img">
                <div className="image">
                  <img src={mainImage} />
                </div>
                <div className="list-img">
                  {product.image.map((img, index) => (
                    <>
                      <div
                        className={`list-item ${
                          selectedImage === img ? "active" : ""
                        }`}
                      >
                        <img
                          src={img}
                          alt=""
                          key={index}
                          onClick={() => {
                            setMainImage(img);
                            setSelectedImage(img);
                          }}
                        />
                      </div>
                    </>
                  ))}
                </div>
              </div>
              <div className="box-info">
                <h2>{product.Name}</h2>
                <div className="icon">
                  <span> 4.8</span> <FaStar className="star" />{" "}
                </div>
                <p className="seen">985 lượt xem</p>

                <p className="price">Giá: {formater(product.price)}</p>
                <p className="quality">
                  100% hàng <strong>hàng chất lượng</strong> đảm bảo{" "}
                  <strong>uy tín</strong>
                </p>
                <h2>Chi Tiết Sản Phẩm</h2>
                <p className="desc">{product.description}</p>
                <button className="add" onClick={() => addToCart(product)}>
                  Thêm vào giỏ hàng
                </button>
              </div>
            </div>
          </div>
          <section>
            <Rating />
          </section>
        </div>
      </div>
      {/* <div>
        <h1>Chi tiết sản pha </h1>
        <p>Tên: {product.Name}</p>
        <p>Mô tả: {product.description}</p>
        <p>Giá: {product.price} VND</p>
      </div> */}
    </>
  );
};

export default Detail;
