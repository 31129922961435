import React, { useState } from "react";
import "./tab.scss";
// import img1 from "../../assets/image/ban_tin/img1.jpg";
// import img2 from "../../assets/image/ban_tin/img2.jpg";
// import img3 from "../../assets/image/ban_tin/img3.jpg";
// import img4 from "../../assets/image/ban_tin/img4.jpg";
import { Link } from "react-router-dom";

import { Congnghe, Hoidap } from "../Data/Bantin";
const Tab = () => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const [seeMore, setSeeMore] = useState(4); // lúc đầu hiển thị 8 sản phẩm
  const slice = Congnghe.slice(0, seeMore);
  //.slice(start, end) là một phương thức của mảng trong JavaScript, giúp tạo
  //ra một mảng mới chứa các phần tử được lấy từ start đến end - 1 của mảng gốc (end không được bao gồm).
  const xemThem = () => {
    setSeeMore(seeMore + 4); // mỗi lần bấm xem thêm thì + 4 sản phẩm
  };
  return (
    <>
      <div className="container-news">
        <div className="news">
          <div className="news-title">
            <h2>Bài tin</h2>
          </div>
          <div className="bloc-tabs">
            <div
              className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(1)}
            >
              <button>24h công nghệ</button>
            </div>
            <div
              className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(2)}
            >
              <button>Hỏi đáp</button>
            </div>
          </div>
          <div className="container-content">
            <div
              className={
                toggleState === 1 ? "congnghe active-content" : "congnghe"
              }
            >
              <div className="content-tabs">
                {slice.map((ele) => {
                  return (
                    <div className="box-new" key={ele.id}>
                      <div className="img-new">
                        <img src={ele.image} alt="img-new" />
                      </div>
                      <div className="info-new">
                        <Link to={`/tin-tuc/${ele.id}`}>
                          <p>{ele.description}</p>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
              <h4 onClick={xemThem}>Xem thêm bài tin &gt; </h4>
            </div>
            <div
              className={
                toggleState === 2 ? "congnghe active-content" : "congnghe"
              }
            >
              <div className="content-tabs">
                {Hoidap.map((ele) => {
                  return (
                    <div className="box-new">
                      <div className="img-new">
                        <img src={ele.image} alt="img-new" />
                      </div>
                      <div className="info-new">
                        <Link to={`/24h-cong-nghe/${ele.id}`}>
                          <p>{ele.description}</p>
                        </Link>
                        <div className="more">
                          <a href="#">Xem thêm</a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <h4>Xem thêm bài tin &gt; </h4>
            </div>
          </div>
        </div>
        {/* <div className="bloc-tabs">
          <div
            className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(1)}
          >
            tab1
          </div>
          <div
            className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(2)}
          >
            tab2
          </div>
          <div
            className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(3)}
          >
            tab3
          </div>
        </div>
        <div className="content-tabs">
          <div
            className={toggleState === 1 ? "content active-content" : "content"}
          >
            <h2>Content 1</h2>
            <hr />
            <p>
              shajsh hajshy hjhajs jhjhdhjdh jhsdjhajd jhsdjha jhsdjhashdjhasdjd
              sjhdjahs sdjahdhasdj
            </p>
          </div>
          <div
            className={toggleState === 2 ? "content active-content" : "content"}
          >
            <h2>Content 2</h2>
            <hr />
            <p>
              shajsh hajshy hjhajs jhjhdhjdh jhsdjhajd jhsdjha jhsdjhashdjhasdjd
              sjhdjahs sdjahdhasdj
            </p>
          </div>
          <div
            className={toggleState === 3 ? "content active-content" : "content"}
          >
            <h2>Content 3</h2>
            <hr />
            <p>
              shajsh hajshy hjhajs jhjhdhjdh jhsdjhajd jhsdjha jhsdjhashdjhasdjd
              sjhdjahs sdjahdhasdj
            </p>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Tab;
