import img1 from "../../assets/image/ban_tin/img1.jpg";
import img2 from "../../assets/image/ban_tin/img2.jpg";
import img3 from "../../assets/image/ban_tin/img3.jpg";
import img4 from "../../assets/image/ban_tin/img4.jpg";
import h1 from "../../assets/image/ban_tin/h1.jpg";
import h2 from "../../assets/image/ban_tin/h2.jpg";
import h3 from "../../assets/image/ban_tin/h3.jpg";
import h4 from "../../assets/image/ban_tin/h4.jpg";
export const Congnghe = [
  {
    id: 1,
    image: img1,
    description:
      "iPhone 16 | iPhone 16 Plus giá chỉ từ 22.59 triệu, 5 màu sắc cực sang cho bạn lựa chọn",
  },
  {
    id: 2,
    image: img2,
    description:
      "Hô biến bàn làm việc gọn gàng nhưng vẫn hiệu suất với Samsung Galaxy Tab S10 Ultra",
  },
  {
    id: 3,
    image: img3,
    description:
      "Rò rỉ thông số Vivo S20 Pro: Bốn camera 50MP với ống kính tiềm vọng, Dimensity 9300+",
  },
  {
    id: 4,
    image: img4,
    description:
      "Thiết kế, cấu hình và ngày ra mắt của dòng OPPO Reno 13 chính thức được xác nhận",
  },
  {
    id: 5,
    image: img4,
    description:
      "Thiết kế, cấu hình và ngày ra mắt của dòng OPPO Reno 13 chính thức được xác nhận",
  },
];

export const Hoidap = [
  {
    id: 1,
    image: h1,
    description:
      "Tầm 15 triệu mua điện thoại gì? TOP 5 điện thoại 15 triệu tốt nhất",
  },
  {
    id: 2,
    image: h2,
    description: "TOP 5 điện thoại cục gạch giá rẻ dưới 500K tại BAT SHOP",
  },
  {
    id: 3,
    image: h3,
    description:
      "TOP 10 đồng hồ nam dưới 1 triệu đẹp, rẻ, bán chạy nhất 2024 tại TGDĐ",
  },
  {
    id: 4,
    image: h4,
    description:
      "TOP 10+ laptop cấu hình mạnh giá rẻ đáng mua nhất thị trường hiện nay",
  },
];
