//Đoạn mã này định nghĩa một hàm JavaScript để định dạng một số thành tiền tệ Việt Nam Đồng (VND)
//export: Từ khóa này được sử dụng để xuất hàm formater từ một module, cho phép hàm này có thể được nhập (import) và sử dụng ở các module khác.
// Intl.NumberFormat là một lớp trong JavaScript cung cấp khả năng định dạng số tùy theo ngôn ngữ. Bạn có thể sử dụng nó để định dạng số thành kiểu hiển thị
//phù hợp cho từng khu vực cụ thể, bao gồm cả định dạng tiền tệ.
export const formater = (number) => {
  return new Intl.NumberFormat("vi-VN", {
    style: "currency", //style: "currency": Đặt kiểu định dạng là tiền tệ.
    currency: "VND", //currency: "VND": Đặt loại tiền tệ là Đồng Việt Nam (VND).
  }).format(number);
};
