import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Nav from "../src/component/Nav/Nav";
import Rout from "./component/Rout/Rout";
import Footer from "./component/Footer/Footer";
import Homeproduct from "./component/Data/HomeProduct_data";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  // trang cửa hàng
  const [shop, setShop] = useState(Homeproduct);
  // hàm lọc các sản phẩm ở trang cửa hàng
  const Filter = (x) => {
    const catefilter = Homeproduct.filter((product) => {
      return product.cat === x;
    });
    setShop(catefilter); //hiển thị các sản phẩm đúng với cat đã lọc
  };
  // hàm hiển thị tất cả ở trang cửa hàng
  const allcatefilter = () => {
    setShop(Homeproduct); // hiển thị toàn bộ sản phẩm
  };

  //hàm tìm kiếm sản phẩm ở thanh nav
  const [search, setSearch] = useState("");
  const errSearch = (search || []).length === 0;
  const searchproduct = () => {
    if (errSearch) {
      alert("Vui lòng nhập tên sản phẩm!!");
      setShop(Homeproduct);
    }
    const searchfilter = Homeproduct.filter((val) => {
      if (search === "") {
        return val;
      } else if (val.Name.toLowerCase().includes(search.toLowerCase())) {
        return val;
      }
    });
    setShop(searchfilter);
  };
  // thêm vào giỏ hàng
  const [cart, setCart] = useState([]);
  //Hàm thêm vào giỏ hàng
  // const notify = () => toast("chưa thêm!");
  const addToCart = (product) => {
    const exist = cart.find((x) => {
      return x.id == product.id;
    });
    if (exist) {
      toast.info("Đã tồn tại trong giỏ hàng", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Flip,
      });
    } else {
      setCart([...cart, { ...product, quantity: 1 }]); // vế sau dùng để add thêm vào vế đầu
      //...cart: sao chép toàn bộ giỏ hàng hiện tại
      //{ ...product, qty: 1 }: Sao chép đối tượng product được truyền vào hàm và thêm thuộc tính quantity với giá trị 1,
      // chỉ định rằng sản phẩm mới thêm vào có số lượng là 1.
      toast.success("Thêm thành công!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Flip,
      });
    }
  };
  return (
    <>
      <BrowserRouter>
        <Nav
          search={search}
          setSearch={setSearch}
          searchproduct={searchproduct}
        />
        <Rout
          setCart={setCart}
          cart={cart}
          shop={shop}
          Filter={Filter}
          allcatefilter={allcatefilter}
          addToCart={addToCart}
        />
        <Footer />
      </BrowserRouter>
      <ToastContainer
      // position="top-center"
      // autoClose={1000}
      // hideProgressBar={false}
      // newestOnTop={false}
      // closeOnClick
      // rtl={false}
      // pauseOnFocusLoss
      // draggable
      // pauseOnHover={false}
      // theme="dark"
      />
    </>
  );
}

export default App;
