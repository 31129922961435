import React from "react";
import "./gioithieu.scss";
const Gioithieu = () => {
  return (
    <>
      <div className="max_container">
        <div className="introduce">
          <h1 className="title">Giới thiệu</h1>
          <div className="content">
            <div className="part">
              <h3>I.QUÁ TRÌNH HÌNH THÀNH VÀ PHÁT TRIỂN</h3>
              <p>
                Trong những năm qua, xã hội phát triển, kinh tế tăng trưởng đồng
                thời là chất lượng cuộc sống của người dân ngày càng càng được
                nâng cao nhiều trung tâm thương mại, nhà cao tầng, biệt thự được
                mọc ra kèm theo đấy là nhu cầu mua sắm các thiết bị phục vụ nhu
                cầu cuộc sống hàng ngày như TV, Tủ lạnh, Điện gia dụng...
              </p>
              <p>
                Thế giới điện tử Accent khai trương Siêu thị Số 442 Đội Cấn -
                Cống Vị - Ba Đình - Hà Nội chính thức tham gia vào lĩnh vực kinh
                doanh bán lẻ điện tử, tạo ra một phong cách mua sắm hoàn toàn
                mới với người dân thủ đô, thông qua cung cấp các sản phẩm và
                dịch vụ tới người tiêu dùng.
              </p>
              <h3>• TẦM NHÌN:</h3>
              <p>
                Công ty số 1 tại Việt Nam trong lĩnh vực phân phối, bán lẻ các
                sản phẩm điện tử.
              </p>
              <h3>• SỨ MỆNH:</h3>
              <p>
                Với kim chỉ nam là “Không ngừng phát triển vì khách hàng” và làm
                hài lòng khách hàng bằng cách tạo ra những giá trị gia tăng như
                cung cấp các sản phẩm, dịch vụ tốt nhất.
              </p>
              <h3>• MỤC TIÊU CHIẾN LƯỢC:</h3>
              <p>
                1. Tối đa hoá giá trị đầu tư của các cổ đông; giữ vững tốc độ
                tăng trưởng lợi nhuận và tình hình tài chính lành mạnh;
              </p>
              <p>
                2. Không ngừng nâng cao động lực làm việc và năng lực cán bộ;
                Accent phải luôn dẫn đầu ngành điện tử trong việc sáng tạo, phát
                triển chính sách đãi ngộ và cơ hội thăng tiến nghề nghiệp cho
                cán bộ của mình;
              </p>
              <p>
                3. Duy trì sự hài lòng, trung thành và gắn bó của khách hàng với
                Accent; xây dựng Accent thành một trong những công ty hàng đầu
                Việt Nam có chất lượng dịch vụ tốt nhất do khách hàng lựa chọn.
              </p>
              <p>
                4. Phát triển Accent thành một trong những nhà bán lẻ hàng đầu
                Việt Nam về: quản lý tốt nhất, môi trường làm việc tốt nhất, văn
                hoá doanh nghiệp chú trọng khách hàng, thúc đẩy hợp tác và sáng
                tạo, linh hoạt nhất khi môi trường kinh doanh thay đổi.
              </p>
              <h3>II. THẾ MẠNH VÀ ĐỊNH HƯỚNG CỦA CÔNG TY</h3>
              <p>
                Với kim chỉ nam là “Không ngừng phát triển vì khách hàng”,
                Accent đã quy tụ được Ban Lãnh đạo có bề dày kinh nghiệm trong
                các lĩnh vực điện tử không chỉ mạnh về kinh doanh mà còn mạnh về
                công nghệ có nhiều tiềm năng phát triển, kết hợp với đội ngũ
                nhân viên trẻ, năng động và chuyên nghiệp tạo lên thế mạnh nòng
                cốt của công ty để thực hiện tốt các mục tiêu đề ra.
              </p>
              <p>
                Hơn nữa, trên cơ sở nguồn lực của công ty và nhu cầu của xã hội,
                Accent lựa chọn phát triển kinh doanh các sản phẩm Điện tử phục
                vụ nhu cầu thiết yếu của người dân với các sản phẩm đa dạng
                phong phú mang lại giá trị gia tăng cho người tiêu dùng thông
                qua các dịch vụ sau bán hàng.
              </p>
              <p>
                Qua quá trình phát triển, bên cạnh việc thiết lập được một hệ
                thống đối tác nước trong nước và ngoài đến từ các doanh nghiệp
                lớn của Korea, Singapore, Trung Quốc, Nhật Bản, có thế mạnh
                trong các lĩnh vực Điện máy, sản phẩm công nghệ như: Samsung,
                Sony, Panasonic, Toshiba, Sharp,... Trong thời gian tới Công ty
                sẽ đầu tư vào các ngành nghề mới như bất động sản, khai thác
                khoáng, đầu tư tài chính...
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gioithieu;
