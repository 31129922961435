import React, { useState } from "react";
import Banner from "../Banner/Banner";
import "./home.scss";
import Sliders from "../Slider/Slider";

import Homeproduct from "../Data/HomeProduct_data";
import { formater } from "../utils/format";
import {
  FaArrowLeft,
  FaArrowRight,
  FaEye,
  FaFacebook,
  FaHeart,
  FaInstagram,
  FaShoppingCart,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import banner1 from "../../assets/image/banner/img1.png";
import banner2 from "../../assets/image/banner/img2.png";
import banner3 from "../../assets/image/banner/img3.png";
import banner4 from "../../assets/image/banner/img4.png";
import Tab from "../Tab/Tab";
import { Link } from "react-router-dom";

const Home = ({ addToCart }) => {
  //dùng để hiển thị các sản phẩm ở mục các sản phẩm thịnh hành
  const [trendingProduct, setTrendingProduct] = useState(Homeproduct);
  //Hàm để xem thêm sản phẩm khi ấn nút
  const [seeMore, setSeeMore] = useState(10); // lúc đầu hiển thị 10 sản phẩm
  const slice = trendingProduct.slice(0, seeMore);
  //.slice(start, end) là một phương thức của mảng trong JavaScript, giúp tạo
  //ra một mảng mới chứa các phần tử được lấy từ start đến end - 1 của mảng gốc (end không được bao gồm).
  const xemThem = () => {
    setSeeMore(seeMore + 5); // mỗi lần bấm xem thêm thì + 5 sản phẩm
  };
  //HÀM LỌC CÁC DANH MỤC
  const filterCategory = (category) => {
    // hàm này được dùng để onclick
    const filterProduct = Homeproduct.filter((curElm) => {
      return curElm.type === category; // trả về type loại sản phẩm mà bằng với "category" chuyền vào
    });
    setTrendingProduct(filterProduct); // set để hiển thị các sản phẩm của danh mục
  };
  //KHI CLICK VÀO 'SẢN PHẨM THỊNH HÀNH' THỊ HIỂN THỊ TẤT CẢ CÁC SẢN PHẨM
  const allTrendingProduct = () => {
    setTrendingProduct(Homeproduct); // hiển thị tất cả sản phẩm(về trạng thái lúc đầu mới đặt)
  };
  return (
    <>
      <Banner />
      <Sliders />
      <div className="max_container">
        <div className="trending">
          <div className="container">
            <div className="header">
              <div className="heading">
                <h2 onClick={() => allTrendingProduct()}>
                  SẢN PHẨM THỊNH HÀNH
                </h2>
              </div>
              <div className="category">
                <h3 onClick={() => filterCategory("new")}>Mới</h3>
                <h3 onClick={() => filterCategory("featured")}>
                  Sản phẩm nổ bật
                </h3>
                <h3 onClick={() => filterCategory("top")}>Bán chạy</h3>
              </div>
            </div>
            <div className="products">
              <div className="container">
                {slice.map((curElm) => {
                  return (
                    <>
                      <div className="box">
                        <div className="img_box">
                          <img src={curElm.image[0]} alt="" />
                          <div className="icon">
                            <div className="icon_box">
                              <Link to={`/san-pham/${curElm.id}`}>
                                <FaEye />
                              </Link>
                            </div>
                            <div className="icon_box">
                              <FaHeart />
                            </div>
                          </div>
                        </div>
                        <div className="info">
                          <Link to={`/san-pham/${curElm.id}`}>
                            <h3>{curElm.Name}</h3>
                          </Link>
                          <p>{formater(curElm.price)}</p>
                          <button
                            className="btn"
                            onClick={() => addToCart(curElm)}
                          >
                            Thêm vào giỏ hàng
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <button onClick={() => xemThem()}>Xem thêm</button>
            </div>
          </div>
        </div>
        <section>
          <div className="banner-offers">
            <div className="banner-title">
              <h2>Gian hàng ưu đãi</h2>
            </div>
            <div className="box-banner-img">
              <div className="banner-img">
                <img src={banner1} alt="" />
              </div>
              <div className="banner-img">
                <img src={banner2} alt="" />
              </div>
              <div className="banner-img">
                <img src={banner3} alt="" />
              </div>
              <div className="banner-img">
                <img src={banner4} alt="" />
              </div>
            </div>
          </div>
        </section>
        <section>
          <Tab />
        </section>
      </div>
    </>
  );
};

export default Home;
