import React, { useEffect, useState } from "react";
import "./banner.scss";
import imgSide from "../Data/banner_banner";

const Banner = () => {
  const [currentState, setCurrentState] = useState(0);
  //Hàm để banner tự động chạy
  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentState === imgSide.length - 1) {
        setCurrentState(0);
      } else {
        setCurrentState(currentState + 1);
      }
    }, 3250);
    return () => clearTimeout(timer); // // Xóa thời gian chờ khi unmount component để tránh rò rỉ bộ nhớ
  }, [currentState]);
  //React chỉ chạy lại useEffect khi giá trị trong mảng phụ thuộc thay đổi.
  //Ở đây, currentState là biến trạng thái đại diện cho hình ảnh hiện tại. Mỗi khi currentState thay đổi (tức là chỉ mục hình ảnh được cập nhật),
  // useEffect sẽ chạy lại, khởi động một bộ hẹn giờ mới để tiếp tục thay đổi hình ảnh.
  //Nếu không có , [currentState], React sẽ không biết rằng nó cần chạy lại useEffect khi currentState thay đổi, dẫn đến hành vi không mong muốn.

  const bgImageStyle = {
    backgroundImage: `url(${imgSide[currentState].url})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "500px",
  };
  const goToNext = (index) => {
    setCurrentState(index);
  };
  return (
    <>
      <div className="max_container">
        <div className="container-style">
          <div style={bgImageStyle}></div>

          <div className="next-slider">
            {imgSide.map((img, index) => {
              return (
                <span
                  key={index}
                  onClick={() => goToNext(index)}
                  className={index === currentState ? "active" : ""}
                ></span>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
