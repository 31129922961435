import image1 from "../../assets/image/banner/banner1.jpg";
import image2 from "../../assets/image/banner/banner2.jpg";
import image3 from "../../assets/image/banner/banner3.jpg";
import image4 from "../../assets/image/banner/banner4.png";
const imgSide = [
  {
    url: image1,
    title: "hghghg shhahg jhjsha",
    body: "sdshd jhjshaj iuiusa jsjdhaj sgahs",
  },
  {
    url: image2,
    title: "hghghg shhahg jhjsha",
    body: "sdshd jhjshaj iuiusa jsjdhaj sgahs",
  },
  {
    url: image3,
    title: "hghghg shhahg jhjsha",
    body: "sdshd jhjshaj iuiusa jsjdhaj sgahs",
  },
  {
    url: image4,
    title: "hghghg shhahg jhjsha",
    body: "sdshd jhjshaj iuiusa jsjdhaj sgahs",
  },
];
export default imgSide;
