import React, { useEffect, useRef, useState } from "react";
import "./slider.scss";

import gift from "../../assets/image/san_sale/gift.png";
import { ProductData } from "../../component/Data/Product_Slider";

// react slick slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { formater } from "../utils/format";
import { FaStar } from "react-icons/fa";

const Sliders = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };

  // HÀM format thời gian(nhập giây vào hiện ra giờ, phút, giây)
  const formatTime = (time) => {
    let hours = Math.floor(time / 3600); // Math.floor làm tròn xuống một số về số nguyên gần nhất nhỏ hơn hoặc bằng nó
    let minutes = Math.floor((time % 3600) / 60);
    let seconds = Math.floor(time % 60);

    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;

    return hours + ":" + minutes + ":" + seconds;
  };
  // Hàm đếm lùi thời gian
  const CountDown = (seconds) => {
    const [countdown, setCountdown] = useState(seconds);
    const timerId = useRef();
    //Vì timerId được lưu bằng useRef, giá trị của nó sẽ không thay đổi hoặc bị mất sau khi component re-render.
    //Điều này rất quan trọng khi làm việc với các hàm như setInterval hoặc setTimeout,
    //để đảm bảo bạn có thể xóa hoặc dừng chúng một cách chính xác.

    useEffect(() => {
      //Đây là một đoạn mã React sử dụng useEffect kết hợp với setInterval để tạo một bộ đếm lùi
      timerId.current = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timerId.current); //Xóa interval đã tạo, ngăn không cho bộ đếm tiếp tục chạy trong nền.
    }, []); //Nếu [] rỗng, effect chỉ chạy một lần duy nhất khi component được mount.

    useEffect(() => {
      if (countdown <= 0) {
        //Khi countdown đạt giá trị 0 hoặc âm, logic bên trong if sẽ chạy.
        clearInterval(timerId.current);
        alert("FLASH SALE đã kết thúc");
      }
    }, [countdown]); //Khi giá trị countdown thay đổi, useEffect sẽ được kích hoạt.

    return <span>{formatTime(countdown)}</span>;
  };

  return (
    <>
      <div className="max_container">
        <div className="slider">
          <div className="container-slider">
            <div className="slider-content">
              <div className="slider-title">
                <h2>Flash Sale Kết Thúc Sau: {CountDown(1000)}</h2>
              </div>
              <div className="slider-product">
                <div className="slider-product-items-box">
                  <div className="slider-product-items">
                    <Slider {...settings} className="space">
                      {ProductData.map((x) => {
                        return (
                          <div className="slider-product-item">
                            <img src={x.image} alt="" className="img-1" />
                            <div className="slider_text">
                              <li>
                                <img src={gift} alt="" />
                                <p>ĐỔI 4G TẶNG 480K</p>
                              </li>
                              <li>{x.name}</li>
                              <li>Online giá rẻ quá</li>
                              <li>
                                <a href="">{formater(x.priceOld)}</a>{" "}
                                <span>-6%</span>
                              </li>
                              <li>{formater(x.price)}</li>
                              <li>
                                Quà 400.000<sup>đ</sup>
                              </li>
                              <li>
                                5
                                <i>
                                  <FaStar />
                                </i>
                              </li>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sliders;
