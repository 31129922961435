import image1 from "../../assets/image/san_sale/flip6.jpg";
import image2 from "../../assets/image/san_sale/jpl.jpg";
import image3 from "../../assets/image/san_sale/laptopg.jpg";
import image4 from "../../assets/image/san_sale/ip16.webp";
import image5 from "../../assets/image/san_sale/s24fewebp.webp";

export const ProductData = [
  {
    id: 1,
    name: "SamSung Galaxy Z Flip6 ",
    priceOld: 23500000,
    discount: "",
    price: 18990000,
    image: image1,
  },
  {
    id: 2,
    name: "Loa JBL Pulse 4 ",
    priceOld: 6590000,
    discount: "",
    price: 4990000,
    image: image2,
  },
  {
    id: 3,
    name: "Laptop MSI Cyborg 15 AI ",
    priceOld: 30990000,
    discount: "",
    price: 27490000,
    image: image3,
  },
  {
    id: 4,
    name: "IPhone 16 256GB | VN/A",
    priceOld: 25990000,
    discount: "",
    price: 22590000,
    image: image4,
  },
  {
    id: 5,
    name: "SamSung Galaxy S24 FE",
    priceOld: 25990000,
    discount: "",
    price: 16990000,
    image: image5,
  },
];
