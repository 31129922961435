import React, { useState } from "react";
import "./shop.scss";
import banner from "../../assets/image/banner/cuahang.png";
import { FaEye, FaHeart } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { formater } from "../utils/format";
import { Link } from "react-router-dom";

const Shop = ({ shop, Filter, allcatefilter, addToCart }) => {
  const [seeMore, setSeeMore] = useState(8); // lúc đầu hiển thị 8 sản phẩm
  const slice = shop.slice(0, seeMore);
  //.slice(start, end) là một phương thức của mảng trong JavaScript, giúp tạo
  //ra một mảng mới chứa các phần tử được lấy từ start đến end - 1 của mảng gốc (end không được bao gồm).
  const xemThem = () => {
    setSeeMore(seeMore + 4); // mỗi lần bấm xem thêm thì + 4 sản phẩm
  };
  return (
    <>
      <div className="max_container">
        <div className="shop">
          <h2># CỬA HÀNG</h2>
          <p>Trang chủ . Cửa hàng</p>
          <div className="container">
            <div className="left-box">
              <div className="category">
                <div className="header">
                  <h3> DANH MỤC SẢN PHẨM</h3>
                </div>
                <div className="box">
                  <ul>
                    <li onClick={() => allcatefilter()}>Tất cả</li>
                    <li onClick={() => Filter("TV")}>TV</li>
                    <li onClick={() => Filter("laptop")}>Laptop</li>
                    <li onClick={() => Filter("dong ho")}>Đồng hồ</li>
                    <li onClick={() => Filter("dien tu")}>Điện tử</li>
                    <li onClick={() => Filter("tai nghe")}>Tai nghe</li>
                    <li onClick={() => Filter("dien thoai")}>Điện thoại</li>
                    <li onClick={() => Filter("loa")}>Loa</li>
                  </ul>
                </div>
              </div>
              <div className="banner">
                <div className="img_box">
                  <img src="image/shop_left.avif" alt="banner" />
                </div>
              </div>
            </div>
            <div className="right-box">
              <div className="banner">
                <div className="img_box">
                  <img src={banner} alt="" />
                </div>
              </div>
              <div className="product_box">
                <h2>SẢN PHẨM</h2>
                <div className="product_container">
                  {slice.map((curElm) => (
                    <div className="box">
                      <div className="img_box">
                        <img src={curElm.image[0]} alt="" />
                      </div>
                      <div className="detail">
                        <Link to={`/san-pham/${curElm.id}`}>
                          <h3>{curElm.Name}</h3>
                        </Link>
                        <p>{formater(curElm.price)}</p>
                        <div className="btn">
                          <button
                            className="add"
                            onClick={() => addToCart(curElm)}
                          >
                            Thêm vào giỏ hàng
                          </button>

                          <button className="heart">
                            {" "}
                            <FaHeart />
                          </button>
                          <button className="eye">
                            <Link to={`/san-pham/${curElm.id}`}>
                              <FaEye />
                            </Link>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <button className="seeMore" onClick={xemThem}>
                Xem thêm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shop;
