import React, { useState } from "react";
import "./nav.scss";
import { Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  FaShoppingCart,
  FaTruck,
  FaHeadset,
  FaMoneyBill,
  FaStar,
} from "react-icons/fa";

const Nav = ({ search, setSearch, searchproduct }) => {
  // dùng cho chức năng đăng nhập
  const { loginWithRedirect } = useAuth0();
  // dùng cho chức năng đăng xuất
  const { logout } = useAuth0();
  // dùng cho chức năng hiển thị thông tin người dùng đang đăng nhập
  const { user, isAuthenticated } = useAuth0();
  //isAuthenticated trong Auth0 là một thuộc tính được sử dụng để xác định xem người dùng có đang
  //đăng nhập hay không. Khi sử dụng Auth0 trong ứng dụng React,
  // bạn có thể truy cập thuộc tính này thông qua useAuth0 hook của Auth0.

  //CHỨC NĂNG IN ĐÂM PHẦN ĐƯỢC CHỌN TRÊN THANH NAV
  // Trạng thái lưu mục được chọn
  const [active, setActive] = useState("/");

  return (
    <div>
      {/* Top Bar */}
      <div className="top-bar">
        <p>Miễn phí vận chuyển cho đơn hàng trên 700.000đ</p>
        <div className="top-bar-right">
          <Link to="/cart">
            {" "}
            <FaShoppingCart />
          </Link>
        </div>
      </div>

      {/* Main Nav Bar */}
      <div className="navbar">
        <div className="box-nav">
          <div className="logo">BAT SHOP</div>

          <input
            type="text"
            placeholder="Tìm kiếm..."
            className="search-input"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
          <button className="search-button" onClick={searchproduct}>
            TÌM KIẾM
          </button>

          <div className="auth-buttons">
            {isAuthenticated ? (
              <button
                className="register-button"
                onClick={() =>
                  logout({
                    logoutParams: { returnTo: window.location.origin },
                  })
                }
              >
                ĐĂNG XUẤT
              </button>
            ) : (
              <button
                className="login-button"
                onClick={() => loginWithRedirect()}
              >
                ĐĂNG NHẬP
              </button>
            )}
          </div>
          <div className="cart-icon">
            {
              //profile người dùng hiển thị ở đây khi đăng nhập
              isAuthenticated ? (
                // nếu đang đăng nhập thì hiển thị tên và email (user được định nghĩa trong auth0)
                <>
                  <div className="icon">{/* <CiUser /> */}</div>
                  <div className="info">
                    <h2>{user.name}</h2>
                    {/* <p>{user.email}</p> */}
                  </div>
                </>
              ) : (
                // khi chưa đăng nhập
                <>
                  <div className="icon">{/* <CiUser /> */}</div>
                  <div className="info">
                    <p>Vui lòng đăng nhập</p>
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>

      {/* Navigation Links */}
      <div className="nav-links">
        <Link
          to="/"
          className={active === "/" ? "active" : ""}
          onClick={() => setActive("/")}
        >
          TRANG CHỦ
        </Link>
        <Link
          to="/gioi-thieu"
          className={active === "gioithieu" ? "active" : ""}
          onClick={() => setActive("gioithieu")}
        >
          GIỚI THIỆU
        </Link>
        <Link
          to="/cua-hang"
          className={active === "cuahang" ? "active" : ""}
          onClick={() => setActive("cuahang")}
        >
          CỬA HÀNG
        </Link>

        <Link
          to="/lien-he"
          className={active === "lienhe" ? "active" : ""}
          onClick={() => setActive("lienhe")}
        >
          LIÊN HỆ
        </Link>
        <Link
          to="/gio-hang"
          className={active === "giohang" ? "active" : ""}
          onClick={() => setActive("giohang")}
        >
          <FaShoppingCart />
          <span>GIỎ HÀNG</span>
        </Link>
      </div>

      {/* Info Row */}
      <div className="info-row">
        <div>
          <FaTruck /> MIỄN PHÍ VẬN CHUYỂN
        </div>
        <div>
          <FaHeadset /> HỖ TRỢ 24/7
        </div>
        <div>
          <FaMoneyBill /> HOÀN TIỀN TRONG 15 NGÀY
        </div>
        <div>
          <FaStar /> GIẢM GIÁ THEO ĐƠN HÀNG
        </div>
      </div>
    </div>
  );
};

export default Nav;
