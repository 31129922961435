import ip15Pro from "../../assets/image/product/ip15Pro.jpg";
import s24ul from "../../assets/image/product/s24ul.jpg";
import mac16 from "../../assets/image/product/mac16.jpg";
import tvsonny from "../../assets/image/product/tvsonny.jpg";
import prd1 from "../../assets/image/product/prd1.png";
import prd2 from "../../assets/image/product/prd2.png";
import prd3 from "../../assets/image/product/prd3.png";
import prd4 from "../../assets/image/product/prd4.png";
import prd5 from "../../assets/image/product/prd5.png";
import prd6 from "../../assets/image/product/prd6.jpg";
import prd7 from "../../assets/image/product/prd7.png";
import prd8 from "../../assets/image/product/prd8.png";
import prd9 from "../../assets/image/product/prd9.png";
import prd10 from "../../assets/image/product/prd10.jpg";
import prd11 from "../../assets/image/product/prd11.png";

const Homeproduct = [
  {
    id: 1,
    Name: "TV Samsung G435 75 inch 4k 165hz...",
    price: "2600000",
    image: [prd1, prd4, prd5],
    cat: "TV",
    type: "new",
    description:
      "Tận hưởng màu sắc chân thực nhờ công nghệ Dynamic Crystal 4K \n Dynamic Crystal Color đắm mình vào khung hình" +
      " Dễ dàng tìm kiếm nội dung nhờ Trợ lý ảo Google Multi View xem nhiều nội dung trên một khung hình",
  },
  {
    id: 2,
    Name: "Loa bluetooth âm thanh sóng động",
    price: "1700000",
    image: [prd2, prd4, prd5],
    cat: "loa",
    type: "new",
    description:
      "Bang Max được trang bị 3, bao gồm hai loa trầm 30W, hai “tweeter trung” 20W, hai loa tweeter 10W và hai bộ tản âm bass thụ động." +
      "Tân tiến hơn so với các dòng loa đàn anh, giờ đây, Bang Max chính xác là dòng loa dành cho bữa tiệc khi được trang bị thêm 3 cổng, 2 cổng micro có dây và 1 cổng guitar." +
      "Và nếu bạn muốn thực sự mang đến bữa tiệc, công nghệ TuneConn của Tronsmart cho phép bạn đồng bộ hóa tới 100 loa Tronsmart khác." +
      "Hơn hết, khả năng chống nước IPX6 sẽ thực hiện hóa các bữa tiệc ngoài trời, bữa tiệc biển hay bể bơi của bạn.",
  },
  {
    id: 3,
    Name: "Apple Watch series 9...",
    price: "8640000",
    image: [prd3, prd4, prd5],
    cat: "dong ho",
    type: "new",
    description:
      "Apple Watch Series 9 41mm sở hữu thiết kế khá hiện đại, sang trọng cùng khả năng xử lý nhanh chóng mọi tác vụ nhờ được trang bị chipset S9 SiP mạnh mẽ. Đồng thời, dòng Apple " +
      " smartwatch này còn đi kèm với hàng loạt các tính năng theo dõi sức khỏe hàng đầu như: đo nhịp tim, điện tâm đồ, đo chu kỳ kinh nguyệt chính xác. Đặc biệt là thời lượng sử dụng của Series 9 41mm còn có thể lên tới 36 giờ , giúp kéo dài thời gian trải nghiệm của người dùng.",
  },
  {
    id: 14,
    Name: "Laptop Dell Latitude 3540 71024262",
    price: "21000000",
    image: [prd4, prd7, prd5],
    cat: "laptop",
    type: "featured",
    description:
      "Dell XPS 13 9340 được đánh giá là có những bước tiến đột phá trong phong cách thiết kế so với các phiên bản tiền nhiệm. Với phiên bản trước Dell XPS 13 Plus, phần bàn phím và touchpad được gia công khá là phẳng, " +
      "chìm xuống hẳn dưới vỏ thân máy. Trong sản phẩm lần này, Dell đã mang nguyên thiết kế trên áp dụng cho dòng Dell XPS 13 9340. Do đó khi nhìn vào máy sẽ có bố cục liền mạch hơn, ít có đường nét dư thừa. Mình đánh giá đây là" +
      " sản phẩm có thiết kế đẹp nhất trong các dòng laptop Dell năm nay.",
  },
  {
    id: 4,
    Name: "Loa JBL Boombox",
    price: "8300000",
    image: [prd5, prd4, prd6],
    cat: "loa",
    type: "top",
    description:
      "Bang Max được trang bị 3, bao gồm hai loa trầm 30W, hai “tweeter trung” 20W, hai loa tweeter 10W và hai bộ tản âm bass thụ động." +
      "Tân tiến hơn so với các dòng loa đàn anh, giờ đây, Bang Max chính xác là dòng loa dành cho bữa tiệc khi được trang bị thêm 3 cổng, 2 cổng micro có dây và 1 cổng guitar." +
      "Và nếu bạn muốn thực sự mang đến bữa tiệc, công nghệ TuneConn của Tronsmart cho phép bạn đồng bộ hóa tới 100 loa Tronsmart khác." +
      "Hơn hết, khả năng chống nước IPX6 sẽ thực hiện hóa các bữa tiệc ngoài trời, bữa tiệc biển hay bể bơi của bạn.",
  },

  {
    id: 5,
    Name: "Camera Imou chính hãng",
    price: "1732000",
    image: [prd6, prd4, prd5],
    cat: "camera",
    type: "new",
    description:
      "Bang Max được trang bị 3, bao gồm hai loa trầm 30W, hai “tweeter trung” 20W, hai loa tweeter 10W và hai bộ tản âm bass thụ động." +
      "Tân tiến hơn so với các dòng loa đàn anh, giờ đây, Bang Max chính xác là dòng loa dành cho bữa tiệc khi được trang bị thêm 3 cổng, 2 cổng micro có dây và 1 cổng guitar." +
      "Và nếu bạn muốn thực sự mang đến bữa tiệc, công nghệ TuneConn của Tronsmart cho phép bạn đồng bộ hóa tới 100 loa Tronsmart khác." +
      "Hơn hết, khả năng chống nước IPX6 sẽ thực hiện hóa các bữa tiệc ngoài trời, bữa tiệc biển hay bể bơi của bạn.",
  },
  {
    id: 6,
    Name: "Điện thoại iPhone 12 mini 128GB",
    price: "9750000",
    image: [prd7, prd4, prd5],
    cat: "dien thoai",
    type: "new",
    description:
      "Bang Max được trang bị 3, bao gồm hai loa trầm 30W, hai “tweeter trung” 20W, hai loa tweeter 10W và hai bộ tản âm bass thụ động." +
      "Tân tiến hơn so với các dòng loa đàn anh, giờ đây, Bang Max chính xác là dòng loa dành cho bữa tiệc khi được trang bị thêm 3 cổng, 2 cổng micro có dây và 1 cổng guitar." +
      "Và nếu bạn muốn thực sự mang đến bữa tiệc, công nghệ TuneConn của Tronsmart cho phép bạn đồng bộ hóa tới 100 loa Tronsmart khác." +
      "Hơn hết, khả năng chống nước IPX6 sẽ thực hiện hóa các bữa tiệc ngoài trời, bữa tiệc biển hay bể bơi của bạn.",
  },

  {
    id: 7,
    Name: "Loa Bluetooth Sony XB13",
    price: "1700000",
    image: [prd8, prd4, prd5],
    cat: "loa",
    type: "top",
    description:
      "Bang Max được trang bị 3, bao gồm hai loa trầm 30W, hai “tweeter trung” 20W, hai loa tweeter 10W và hai bộ tản âm bass thụ động." +
      "Tân tiến hơn so với các dòng loa đàn anh, giờ đây, Bang Max chính xác là dòng loa dành cho bữa tiệc khi được trang bị thêm 3 cổng, 2 cổng micro có dây và 1 cổng guitar." +
      "Và nếu bạn muốn thực sự mang đến bữa tiệc, công nghệ TuneConn của Tronsmart cho phép bạn đồng bộ hóa tới 100 loa Tronsmart khác." +
      "Hơn hết, khả năng chống nước IPX6 sẽ thực hiện hóa các bữa tiệc ngoài trời, bữa tiệc biển hay bể bơi của bạn.",
  },
  {
    id: 8,
    Name: "Loa JPL Go 4",
    price: "9100000",
    image: [prd9, prd4, prd5],
    cat: "loa",
    type: "new",
    description:
      "Bang Max được trang bị 3, bao gồm hai loa trầm 30W, hai “tweeter trung” 20W, hai loa tweeter 10W và hai bộ tản âm bass thụ động." +
      "Tân tiến hơn so với các dòng loa đàn anh, giờ đây, Bang Max chính xác là dòng loa dành cho bữa tiệc khi được trang bị thêm 3 cổng, 2 cổng micro có dây và 1 cổng guitar." +
      "Và nếu bạn muốn thực sự mang đến bữa tiệc, công nghệ TuneConn của Tronsmart cho phép bạn đồng bộ hóa tới 100 loa Tronsmart khác." +
      "Hơn hết, khả năng chống nước IPX6 sẽ thực hiện hóa các bữa tiệc ngoài trời, bữa tiệc biển hay bể bơi của bạn.",
  },
  {
    id: 9,
    Name: "Surface Pro 5 LTE Core i5 7300U",
    price: "19990000",
    image: [prd10, prd4, prd5],
    cat: "laptop",
    type: "new",
    description:
      "Bang Max được trang bị 3, bao gồm hai loa trầm 30W, hai “tweeter trung” 20W, hai loa tweeter 10W và hai bộ tản âm bass thụ động." +
      "Tân tiến hơn so với các dòng loa đàn anh, giờ đây, Bang Max chính xác là dòng loa dành cho bữa tiệc khi được trang bị thêm 3 cổng, 2 cổng micro có dây và 1 cổng guitar." +
      "Và nếu bạn muốn thực sự mang đến bữa tiệc, công nghệ TuneConn của Tronsmart cho phép bạn đồng bộ hóa tới 100 loa Tronsmart khác." +
      "Hơn hết, khả năng chống nước IPX6 sẽ thực hiện hóa các bữa tiệc ngoài trời, bữa tiệc biển hay bể bơi của bạn.",
  },
  {
    id: 10,
    Name: "Điện thoại Iphone 15 pro 512gb...",
    price: "25000000",
    image: [ip15Pro, prd4, prd5],
    cat: "dien thoai",
    type: "top",
    description:
      "iPhone 15 256GB được cải tiến vượt bậc về cấu hình nhờ được trang bị con chip A16 Bionic 2 lõi hiệu năng cho tốc độ nhanh chóng và tiết kiệm điện hơn. Camera với cảm biến chính 48MP cho chất lượng ảnh sắc nét đáng kinh ngạc. Máy sử dụng viên pin dung lượng lớn và hỗ trợ sạc nhanh 20W tiện lợi. " +
      "Đặc biệt, Dynamic Island trên iPhone 14 Pro và Promax nay đã được trang bị trên iPhone 256GB bản tiêu chuẩn.",
  },
  {
    id: 15,
    Name: "Laptop MSI GF63 - RTX 3050Ti",
    price: "35000000",
    image: [prd11, prd4, prd5],
    cat: "laptop",
    type: "top",
    description:
      "Bang Max được trang bị 3, bao gồm hai loa trầm 30W, hai “tweeter trung” 20W, hai loa tweeter 10W và hai bộ tản âm bass thụ động." +
      "Tân tiến hơn so với các dòng loa đàn anh, giờ đây, Bang Max chính xác là dòng loa dành cho bữa tiệc khi được trang bị thêm 3 cổng, 2 cổng micro có dây và 1 cổng guitar." +
      "Và nếu bạn muốn thực sự mang đến bữa tiệc, công nghệ TuneConn của Tronsmart cho phép bạn đồng bộ hóa tới 100 loa Tronsmart khác." +
      "Hơn hết, khả năng chống nước IPX6 sẽ thực hiện hóa các bữa tiệc ngoài trời, bữa tiệc biển hay bể bơi của bạn.",
  },
  {
    id: 11,
    Name: "Điện thoại Samsung s24 ultra 5g 12gb/512gb...",
    price: "31750000",
    image: [s24ul, prd4, prd5],
    cat: "dien thoai",
    type: "top",
    description:
      "Bang Max được trang bị 3, bao gồm hai loa trầm 30W, hai “tweeter trung” 20W, hai loa tweeter 10W và hai bộ tản âm bass thụ động." +
      "Tân tiến hơn so với các dòng loa đàn anh, giờ đây, Bang Max chính xác là dòng loa dành cho bữa tiệc khi được trang bị thêm 3 cổng, 2 cổng micro có dây và 1 cổng guitar." +
      "Và nếu bạn muốn thực sự mang đến bữa tiệc, công nghệ TuneConn của Tronsmart cho phép bạn đồng bộ hóa tới 100 loa Tronsmart khác." +
      "Hơn hết, khả năng chống nước IPX6 sẽ thực hiện hóa các bữa tiệc ngoài trời, bữa tiệc biển hay bể bơi của bạn.",
  },
  {
    id: 12,
    Name: "MacBook Pro 16 inch 2024 - Nhập khẩu",
    price: "108500000",
    image: [mac16, prd4, prd5],
    cat: "laptop",
    type: "new",
    description:
      "Bang Max được trang bị 3, bao gồm hai loa trầm 30W, hai “tweeter trung” 20W, hai loa tweeter 10W và hai bộ tản âm bass thụ động." +
      "Tân tiến hơn so với các dòng loa đàn anh, giờ đây, Bang Max chính xác là dòng loa dành cho bữa tiệc khi được trang bị thêm 3 cổng, 2 cổng micro có dây và 1 cổng guitar." +
      "Và nếu bạn muốn thực sự mang đến bữa tiệc, công nghệ TuneConn của Tronsmart cho phép bạn đồng bộ hóa tới 100 loa Tronsmart khác." +
      "Hơn hết, khả năng chống nước IPX6 sẽ thực hiện hóa các bữa tiệc ngoài trời, bữa tiệc biển hay bể bơi của bạn.",
  },

  {
    id: 13,
    Name: "Google tv sonny 4k 55inch k-55s30",
    price: "16490000",
    image: [tvsonny, prd4, prd5],
    cat: "TV",
    type: "featured",
    description:
      "Bang Max được trang bị 3, bao gồm hai loa trầm 30W, hai “tweeter trung” 20W, hai loa tweeter 10W và hai bộ tản âm bass thụ động." +
      "Tân tiến hơn so với các dòng loa đàn anh, giờ đây, Bang Max chính xác là dòng loa dành cho bữa tiệc khi được trang bị thêm 3 cổng, 2 cổng micro có dây và 1 cổng guitar." +
      "Và nếu bạn muốn thực sự mang đến bữa tiệc, công nghệ TuneConn của Tronsmart cho phép bạn đồng bộ hóa tới 100 loa Tronsmart khác." +
      "Hơn hết, khả năng chống nước IPX6 sẽ thực hiện hóa các bữa tiệc ngoài trời, bữa tiệc biển hay bể bơi của bạn.",
  },
];

export default Homeproduct;
