import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Home/Home";
import Shop from "../Shop/Shop";
import Detail from "../Detail/Detail";
import Cart from "../Cart/Cart";
import Contact from "../Contact/Contact";
import Gioithieu from "../GioiThieu/Gioithieu";
import NewsDetail from "../News/News/NewsDetail";
import CN_Detail from "../News/CongNghe/CN_Detail";
//dùng để link các page

const Rout = ({ shop, Filter, allcatefilter, addToCart, cart, setCart }) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home addToCart={addToCart} />} />
        <Route
          path="/gio-hang"
          element={<Cart cart={cart} setCart={setCart} />}
        />
        <Route path="/lien-he" element={<Contact />} />
        <Route
          path="/cua-hang"
          element={
            <Shop
              shop={shop}
              Filter={Filter}
              allcatefilter={allcatefilter}
              addToCart={addToCart}
            />
          }
        />
        <Route
          path="/san-pham/:id"
          element={<Detail addToCart={addToCart} />}
        />
        <Route path="/gioi-thieu" element={<Gioithieu />} />
        <Route path="/tin-tuc/:id" element={<NewsDetail />} />
        <Route path="/24h-cong-nghe/:id" element={<CN_Detail />} />
      </Routes>
    </>
  );
};

export default Rout;
