import React, { useState } from "react";
import "./cart.scss";
import { Link } from "react-router-dom";
import { formater } from "../utils/format";
import { MdOutlineClose } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { toast, Flip } from "react-toastify";

const Cart = ({ cart, setCart }) => {
  // hàm tăng sô lượng sản phẩm khi bấm dấu cộng
  const increase = (product) => {
    //hàm kiểm tra sản phẩm tồn tại trong giỏ hàng
    // cách 2 ở dưới hàm decrease
    // const exist = cart.find((x) => {
    //   return x.id === product.id;
    // });
    setCart(
      cart.map((curElm) => {
        return curElm.id === product.id
          ? { ...curElm, quantity: curElm.quantity + 1 }
          : curElm;
      })
    );
  };
  // hàm giảm số lượng sản phẩm khi bấm dấu trừ
  const decrease = (product) => {
    const exist = cart.find((x) => x.id === product.id);

    if (exist && exist.quantity > 1) {
      setCart(
        cart.map((curElm) =>
          curElm.id === product.id
            ? { ...exist, quantity: curElm.quantity - 1 }
            : curElm
        )
      );
    }
  };

  // hàm remove sản phẩm khi ấn vào dấu x
  const removeproduct = (product) => {
    const exist = cart.find((x) => {
      return x.id === product.id;
    });
    if (exist.quantity > 0) {
      // Nếu muốn xóa sản phẩm khỏi giỏ hàng khi số lượng bằng 1, sử dụng dòng dưới
      setCart(cart.filter((curElm) => curElm.id !== product.id)); // chỉ hiển thị các sản phẩm có id khác với sản phẩm bạn muốn xóa
    }
  };

  // THANH TOÁN
  const total = cart.reduce(
    (price, item) => price + item.quantity * item.price,
    0
  );
  //price: Biến tích lũy, giữ tổng giá trị qua các lần lặp.
  //item: Phần tử hiện tại trong mảng cart, đại diện cho từng sản phẩm trong giỏ.
  //price + item.quantity * item.price: Cộng tổng tích lũy price với giá trị của sản phẩm hiện tại (item.quantity * item.price).
  //0: Giá trị khởi tạo cho price, bắt đầu từ 0.

  //HÀM KHI CLICK VÀO THANH TOÁN
  const [showCheckout, setShowCheckout] = useState(false); // lúc đầu trang chi tiết bị ẩn không hiển thị
  // Hàm khi click vào icon mắt sẽ hiển thị chi tiết của sản phẩm
  const detailpage = () => {
    setShowCheckout(true); //Cập nhật trạng thái để hiển thị chi tiết sản phẩm.
  };
  // Hàm đóng trang chi tiết sản phẩm khi ấn vào icon X
  const closedetail = () => {
    setShowCheckout(false);
  };
  // Hàm đóng trang chi tiết sản phẩm khi ấn vào icon X
  const checkout = () => {
    setShowCheckout(false);
    toast.success("Đặt hàng thành công", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Flip,
    });
  };

  return (
    <>
      <div className="max_container">
        {showCheckout ? (
          <div className="cart-checkout">
            <button className="close_btn">
              <AiOutlineClose onClick={closedetail} />
            </button>
            <div className="container">
              <form action="" className="form">
                <h2>Thông tin đặt hàng</h2>
                <label htmlFor="">Họ và tên</label>
                <input type="text" placeholder="Vui lòng nhập họ và tên" />
                <label htmlFor="">Số điện thoại</label>
                <input type="text" placeholder="Vui lòng nhập số điện thoại" />
                <label htmlFor="">Địa chỉ nhận hàng</label>
                <input type="text" placeholder="Nhập địa chỉ nhận hàng" />
                <label htmlFor="">Chọn phương thức thanh toán</label>
                <div className="custom-select">
                  <select name="" id="">
                    <option value="" selected disabled>
                      Chọn...
                    </option>
                    <option value="">Thanh toán khi nhận hàng</option>
                    <option value="">Thanh toán trực tuyến</option>
                  </select>
                </div>
                <button type="button" onClick={checkout}>
                  Đặt hàng
                </button>
              </form>
            </div>
          </div>
        ) : null}
        <div className="cart">
          <h3>#giỏ hàng</h3>
          {cart.length === 0 && (
            <>
              <div className="empty_cart">
                <h2>Bạn chưa có sản phẩm nào trong giỏ hàng</h2>
                <button>
                  <Link to={"/cua-hang"}>Đến cửa hàng</Link>
                </button>
              </div>
            </>
          )}
          <div className="container">
            {cart.map((curElm) => {
              return (
                <>
                  <div className="box">
                    <div className="img_box">
                      <img src={curElm.image[0]} alt="" />
                    </div>
                    <div className="detail">
                      <div className="info">
                        <h4>{curElm.cat}</h4>
                        <h3>{curElm.Name}</h3>
                        <p>Giá: {formater(curElm.price)}</p>
                        <p>
                          Tổng tiền: {formater(curElm.price * curElm.quantity)}
                        </p>
                      </div>
                      <div className="quantity">
                        <button onClick={() => increase(curElm)}>+</button>
                        <input type="number" value={curElm.quantity} />
                        <button onClick={() => decrease(curElm)}>-</button>
                      </div>
                      <div className="icon">
                        <li onClick={() => removeproduct(curElm)}>
                          <MdOutlineClose />
                        </li>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="bottom">
            {cart.length > 0 && (
              <>
                <div className="Total">
                  <h4>Tổng cộng: {formater(total)}</h4>
                </div>
                <button onClick={detailpage}>Thanh toán</button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
