import React from "react";
import "./contact.scss";
import { useState } from "react";
import { ToastContainer, toast, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

const Contact = () => {
  const [user, setUser] = useState({
    //user là một đối tượng có các trường Name, email, subject, và Message, tất cả đều là chuỗi rỗng ban đầu.
    Name: "",
    email: "",
    subject: "",
    Message: "",
  });
  let values, names; //Hai biến values và names được khai báo để giữ giá trị và tên của trường đang được cập nhật trong hàm data.
  const data = (e) => {
    names = e.target.name; // Lấy tên của trường đang được nhập liệu từ thuộc tính name của e.target.
    values = e.target.value; //Lấy giá trị mới của trường từ thuộc tính value của e.target.
    setUser({ ...user, [names]: values });
    //Tạo một bản sao của đối tượng user hiện tại với { ...user }.
    //Cập nhật trường có tên là names bằng values mới, nhờ vào cú pháp [names]: values.
  };
  const send = async (e) => {
    const { Name, email, subject, Message } = user;
    e.preventDefault();
    const option = {
      method: "POST",
      headers: {
        "Content-Type": "aplication/json",
      },
      body: JSON.stringify({
        Name,
        email,
        subject,
        Message,
      }),
    };

    const send = await fetch(
      "https://web-dien-tu-default-rtdb.firebaseio.com/Message.json",
      option
    );

    if (send) {
      toast.success("Gửi thành công", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Flip,
      });
    } else {
      alert("Đã xảy ra lỗi Đã gửi tin nhắn không thành công");
    }
  };
  return (
    <>
      <div className="max_container">
        <div className="contact">
          <div className="row">
            <div className="left-contact">
              <h2>Liên hệ với chúng tôi</h2>
              <form method="POST" className="form">
                <div className="box">
                  <div className="info">
                    <div className="name">
                      <label htmlFor="name">Tên:</label>
                      <input
                        type="text"
                        placeholder="Nhập tên của bạn..."
                        value={user.Name}
                        name="Name"
                        onChange={data}
                      />
                    </div>

                    <div className="email">
                      <label htmlFor="email">Email:</label>
                      <input
                        type="email"
                        placeholder="Nhập email của bạn"
                        value={user.email}
                        name="email"
                        onChange={data}
                      />
                    </div>
                  </div>
                  <div className="subject">
                    <label htmlFor="subject">Chủ đề:</label>
                    <input
                      type="text"
                      placeholder="Chủ đề"
                      value={user.subject}
                      name="subject"
                      onChange={data}
                    />
                  </div>
                  <div className="message">
                    <label htmlFor="message">Tin nhắn:</label>
                    <div className="input">
                      <textarea
                        rows="10"
                        cols="30"
                        name="Message"
                        placeholder="Tin nhắn !"
                        value={user.Message}
                        onChange={data}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button type="submit" onClick={send}>
                  Gửi
                </button>
              </form>
            </div>
            <div className="right-contact">
              <div className="box-right">
                <div className="title">
                  <h2>Thông tin</h2>
                </div>
                <ul>
                  <Link to={"/"}>
                    <li>
                      <IoIosArrowForward /> Trang chủ
                    </li>
                  </Link>
                  <Link to={"/gioi-thieu"}>
                    <li>
                      <IoIosArrowForward /> Giới thiệu
                    </li>
                  </Link>
                  <Link to={"/cua-hang"}>
                    <li>
                      <IoIosArrowForward /> Cửa hàng
                    </li>
                  </Link>
                  <Link to={"/lien-he"} exact>
                    <li>
                      <IoIosArrowForward /> Liên hệ
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
